.Home {
    height: 100vh;
    background: url('./images/Web-Backgrounds/home-web.png') no-repeat center
        fixed;
    background-size: cover;
}

@media (max-width: 768px) {
    .Home {
        display: flex;
        flex-direction: column;
        height: 100vh;
        width: 100%;
        background: url('./images/Mobile-Backgrounds/jagMobile.jpg') no-repeat
            center fixed;
        background-size: cover;
    }
}

.About {
    overflow: auto;
    height: 100vh;
    background: url('./images/Web-Backgrounds/jagBackground.jpg') no-repeat
        center fixed;
    background-size: cover;
}

@media (max-width: 768px) {
    .About {
        height: 100vh;
        width: 100%;
        background: url('./images/Mobile-Backgrounds/jagMobile.jpg') no-repeat
            center fixed;
        background-size: cover;
    }
}

.Services {
    overflow: auto;
    height: 100vh;
    background: url('./images/Web-Backgrounds/services-web.png') no-repeat
        center fixed;
    background-size: cover;
}

@media (max-width: 768px) {
    .Services {
        height: 100vh;
        width: 100%;
        background: url('./images/Mobile-Backgrounds/services-mobile.png') no-repeat
            center fixed;
        background-size: cover;
    }
}

.Contact {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    background: url('./images/Web-Backgrounds/crystalPier.jpg') no-repeat bottom
        fixed;
    background-size: cover;
    margin: auto;
    padding: 0;
}

.Journey {
    overflow: auto;
    height: 100vh;
    width: 100%;
    background: url('./images/Web-Backgrounds/journey-web.jpg') no-repeat
        bottom fixed;
    background-size: cover;
}

@media (max-width: 768px) {
    .Journey {
        display: flex;
        flex-direction: column;
        height: 100vh;
        width: 100%;
        background: url('./images/Mobile-Backgrounds/jagMobile.jpg') no-repeat
            center fixed;
        background-size: cover;
    }
}

@media (max-height: 500px) {
    #smallVertical {
        display: none;
    }
}


